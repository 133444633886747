<template>
  <div>
    <h1>Available Icons</h1>
    <ul class="overflow-auto h-[calc(100vh-100px)]">
      <li v-for="component in componentNames" :key="component">
        <span class="text-black">{{ component }}</span>
        <div><component class="Icon" :is="componentsMap[component]" /></div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, markRaw } from "vue";

const componentNames = ref<string[]>([]);
const componentsMap = ref<Record<string, any>>({});

const loadComponentNames = () => {
  const componentsContext = import.meta.glob("@/components/icons/*.vue");

  Object.keys(componentsContext).forEach(async (filePath) => {
    const componentName = filePath
      .replace(/^.*[\\/]/, "")
      .replace(/\.vue$/, "");

    const component = await componentsContext[filePath]();

    componentNames.value.push(componentName);
    componentsMap.value[componentName] = markRaw(
      (component as { default: any }).default
    );
  });
};

onMounted(() => {
  loadComponentNames();
});
</script>

<style scoped>
h1 {
  margin-bottom: 1rem;
}
ul {
  list-style-type: none;
  padding-left: 50px;
  background: #e0e0e0;
  margin: auto;
  width: 80%;
}
li {
  padding: 0.5rem 0;
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.Icon {
  width: 30px;
  height: 30px;
  /* stroke: black; */
  color: white;
}
</style>
